import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { MapContainer, TileLayer, Marker, Polyline } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import L from "leaflet";

const customIcon = new L.Icon({
  iconUrl: "https://r2.sanvit.me/pngwing.com.png",
  iconSize: [22, 36], // Set this to the actual size of your icon image
  iconAnchor: [16, 32], // Adjust this depending on how you want the icon to be anchored
  popupAnchor: [0, -32],
});

const KAKAO_API_ENDPOINT =
  "https://dapi.kakao.com/v2/local/search/keyword.json";
const KAKAO_API_KEY = "0eca31ac0c831594c17ad517c45ac136"; // Replace with your key
const OSRM_ENDPOINT = "https://osrm-server-nl-1.b-cdn.net/route/v1/foot";

function App() {
  const [destination, setDestination] = useState("");
  const [destinationCoord, setDestinationCoord] = useState(null);
  const [currentPosition, setCurrentPosition] = useState(null);
  const [route, setRoute] = useState(null);
  const [distance, setDistance] = useState(null);
  const [time, setTime] = useState(null);
  const mapRef = useRef(null);

  // useEffect(() => {
  //   if (route && mapRef.current) {
  //     const latLngs = route.map((coord) => [coord[1], coord[0]]);
  //     mapRef.current.fitBounds(latLngs);
  //   }
  // }, [route]);

  useEffect(() => {
    if (route && mapRef.current) {
      const latLngs = route.map((coord) => [coord[1], coord[0]]);
      mapRef.current.fitBounds(latLngs);
    }
    // else if (currentPosition && mapRef) {
    //   mapRef.current.flyTo(currentPosition, 13);
    // }
  }, [route]);

  useEffect(() => {
    navigator.geolocation.getCurrentPosition((position) => {
      setCurrentPosition([position.coords.latitude, position.coords.longitude]);
      if (mapRef.current) {
        mapRef.current.flyTo(
          [position.coords.latitude, position.coords.longitude],
          13
        );
      }
    });
  }, []);

  useEffect(() => {
    const updatePositionAndRoute = () => {
      navigator.geolocation.getCurrentPosition((position) => {
        const newCurrentPosition = [
          position.coords.latitude,
          position.coords.longitude,
        ];
        setCurrentPosition(newCurrentPosition);

        // If there's a destination, fetch the new route
        if (destinationCoord) {
          fetchRoute(
            `${newCurrentPosition[1]},${newCurrentPosition[0]}`,
            `${destinationCoord[1]},${destinationCoord[0]}`
          );
        }
      });
    };

    // Call immediately on mount
    updatePositionAndRoute();

    // Then set it to be called every 10 seconds
    const intervalId = setInterval(updatePositionAndRoute, 10000);

    // Cleanup on unmount
    return () => clearInterval(intervalId);
  }, [destinationCoord]);

  useEffect(() => {
    const interval = setInterval(() => {
      navigator.geolocation.getCurrentPosition((position) => {
        setCurrentPosition([
          position.coords.latitude,
          position.coords.longitude,
        ]);
      });
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  const searchDestination = async () => {
    try {
      const response = await axios.get(KAKAO_API_ENDPOINT, {
        params: { query: destination },
        headers: { Authorization: `KakaoAK ${KAKAO_API_KEY}` },
      });

      if (response.data.documents.length > 0) {
        const destCoord = [
          parseFloat(response.data.documents[0].y),
          parseFloat(response.data.documents[0].x),
        ];
        setDestinationCoord(destCoord);
        if (currentPosition) {
          fetchRoute(
            `${currentPosition[1]},${currentPosition[0]}`,
            `${destCoord[1]},${destCoord[0]}`
          );
        }
      } else {
        alert("목적지를 찾을 수 없습니다");
      }
    } catch (error) {
      console.error("목적지를 찾는 중 오류가 발생하였습니다:", error);
      alert("목적지를 찾는 중 오류가 발생하였습니다");
    }
  };

  const fetchRoute = async (startCoord, endCoord) => {
    try {
      const response = await axios.get(
        `${OSRM_ENDPOINT}/${startCoord};${endCoord}?geometries=geojson&alternatives=false&steps=false&overview=full`
      );
      setRoute(response.data.routes[0].geometry.coordinates);

      // Extract and set distance and time
      setDistance(response.data.routes[0].distance);
      setTime(response.data.routes[0].duration);
    } catch (error) {
      console.error("경로 정보를 가져오는 중 오류가 발생하였습니다:", error);
      alert("경로 정보를 가져오는 중 오류가 발생하였습니다");
    }
  };

  const formatDistance = (distanceInMeters) => {
    if (distanceInMeters >= 1000) {
      return `${(distanceInMeters / 1000).toFixed(2)} km`;
    } else {
      return `${distanceInMeters.toFixed(2)} m`;
    }
  };

  const formatTime = (timeInSeconds) => {
    const hours = Math.floor(timeInSeconds / 3600);
    const minutes = Math.floor((timeInSeconds % 3600) / 60);

    if (hours > 0) {
      return `${hours} 시간 ${minutes} 분`;
    } else {
      return `${minutes} 분`;
    }
  };

  return (
    <div style={{ height: "100vh" }}>
      <MapContainer
        center={currentPosition || [37, 127]}
        zoom={13}
        style={{ height: "100%" }}
        ref={mapRef}
      >
        <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
        {currentPosition && (
          <Marker position={currentPosition} icon={customIcon} />
        )}
        {destinationCoord && (
          <Marker position={destinationCoord} icon={customIcon} />
        )}
        {route && (
          <Polyline
            positions={route.map((coord) => [coord[1], coord[0]])}
            color="blue"
          />
        )}
      </MapContainer>

      <div
        style={{
          position: "absolute",
          top: "10px",
          left: "50%",
          transform: "translateX(-50%)",
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          zIndex: 1000,
        }}
      >
        <input
          value={destination}
          onChange={(e) => setDestination(e.target.value)}
          placeholder="목적지 입력"
          style={{
            padding: "8px",
            fontSize: "14px",
            borderRadius: "12px 0 0 12px",
            border: "2px solid #4a90e2",
            borderRight: "none",
            outline: "none",
            width: "180px",
          }}
        />
        <button
          onClick={searchDestination}
          style={{
            padding: "8px 16px",
            fontSize: "14px",
            borderRadius: "0 12px 12px 0",
            border: "2px solid #4a90e2",
            borderLeft: "none",
            background: "linear-gradient(to right, #4a90e2, #50a2e5)",
            color: "#fff",
            cursor: "pointer",
            outline: "none",
          }}
        >
          Q
        </button>
      </div>
      <div
        style={{
          position: "absolute",
          bottom: "10px",
          left: "50%",
          transform: "translateX(-50%)",
          padding: "5px 10px",
          backgroundColor: "rgba(255, 255, 255, 0.8)",
          borderRadius: "5px",
          boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.2)",
          zIndex: 1000,
        }}
      >
        {distance &&
          time &&
          `${formatDistance(distance)} | ${formatTime(time)}`}
      </div>
    </div>
  );
}

export default App;
